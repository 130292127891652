.editor ul {
    padding: 0 1rem;
}

.editor ol {
    padding: 0 1rem;
}

.editor a {
    cursor: pointer;
    text-decoration: underline;
}
